import { useEffect, useRef, useState } from "react";

import { useUser } from "../../user/utils/user";

import { downloadFile } from "../../common/utils/helpers";
import { clearDB, exportDB, importDB } from "../../data/utils/data";
import { StorageUtils } from "../components/storageWarning";

export default function Debug() {
  const { user } = useUser();
  const [data, setData] = useState("");
  const [availableSpace, setAvailableSpace] = useState("");
  const [totalSpace, setTotalSpace] = useState("");
  const importInputRef = useRef();

  function updateData() {
    exportDB(function (err, jsonString) {
      if (err) {
        console.error(err);
      } else {
        setData(jsonString);
      }
    });
  }

  function clearData() {
    clearDB(function (err) {
      if (err) {
        console.error(err);
      } else {
        updateData();
      }
    });
  }

  function importData(data) {
    importDB(data, function (err) {
      if (err) {
        console.error(err);
      } else {
        updateData();
      }
    });
  }

  function exportData() {
    downloadFile({
      data: data,
      fileName: "export.json",
      fileType: "application/json",
    });
  }

  function handleImportClick() {
    importInputRef.current.click();
  }
  function handleImportChange(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (readerEvent) {
      importData(readerEvent.target.result);
    };
    reader.readAsText(file);
  }

  useEffect(updateData, []);

  // add available space and total space from StorageUtils
  useEffect(() => {
    function fetchStorageInfo() {
      if (navigator.storage?.estimate) {
        navigator.storage.estimate().then(({ usage, quota }) => {
          const availableBytes = quota - usage;

          setAvailableSpace(StorageUtils.formatBytes(availableBytes));
          setTotalSpace(StorageUtils.formatBytes(quota));
        });
      }
    }

    fetchStorageInfo();
  }, []);

  return (
    <div className="text-center space-y-3">
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        Current user information:
      </h3>
      <code
        className="mt-2 block whitespace-pre-wrap overflow-x-scroll"
        data-testid="user-info"
      >
        {JSON.stringify(user)}
      </code>

      <h3
        className="mt-2 text-sm font-medium text-gray-900"
        data-testid="database-size"
      >
        Current database size: {data.length}
      </h3>
      <h3
        className="mt-2 text-sm font-medium text-gray-900"
        data-testid="available-space"
      >
        Available space: {availableSpace}
      </h3>
      <h3
        className="mt-2 text-sm font-medium text-gray-900"
        data-testid="total-space"
      >
        Total space: {totalSpace}
      </h3>
      <div>
        <button
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          onClick={exportData}
        >
          Export current database
        </button>
      </div>

      <div>
        <button
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          onClick={clearData}
        >
          Clear database
        </button>
      </div>

      <div>
        <button
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          onClick={handleImportClick}
        >
          Import database
        </button>
      </div>

      <input
        data-testid="import-database"
        type="file"
        ref={importInputRef}
        onChange={handleImportChange}
        style={{ display: "none" }}
      />

      <h3 className="mt-6 text-sm font-medium text-gray-900">
        Build information
      </h3>
      <div className="text-xs font-medium text-gray-900">
        <p>Build date: {process.env.REACT_APP_CI_COMMIT_TIMESTAMP}</p>
        <p>Branch: {process.env.REACT_APP_CI_COMMIT_REF_NAME}</p>
        <p>Commit: {process.env.REACT_APP_CI_COMMIT_SHA}</p>
        <p>Pipeline: {process.env.REACT_APP_CI_PIPELINE_ID}</p>
        <p>Project Pipeline: {process.env.REACT_APP_CI_PIPELINE_IID}</p>
      </div>
    </div>
  );
}
