import { Menu, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ClockIcon,
  CubeIcon,
  EyeDropperIcon,
  PencilIcon,
  ScaleIcon,
  SignalIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import tzlookup from "tz-lookup";

import { useUser } from "../../user/utils/user";

import Image from "../../common/components/Image.js";
import { formatTime, getImageUrl } from "../../common/utils/helpers.js";
import { getPhenologyOptions } from "../components/CropItem.js";
import { isCorn, isGrain } from "../components/Yield.js";

function DescriptionList({ data, showEdit, t }) {
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const color = data.lowAccuracy ? "red-600" : "gray-900";

  const { user } = useUser();
  function onClick(e) {
    setUrl(e);
    setOpen(true);
  }
  return (
    <div className="mt-4 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
      <Image url={url} open={open} setOpen={setOpen} />
      <header className="-ml-4 -mt-2 md:flex md:items-center md:justify-between md:flex-row sm:flex-nowrap flex flex-col space-x-2 items-center">
        <div className="ml-4 mt-2 min-w-0 flex-1 space-y-2">
          <div className="flex">
            {data.name && (
              <h1 className="text-2xl font-bold text-gray-900 mr-4">
                {data.name}
              </h1>
            )}
            <button
              type="button"
              className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={showEdit}
            >
              <PencilIcon className="-ml-0.5 h-5 w-5 text-gray-400" />
              Edit
            </button>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="mt-2 flex">
              <SignalIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">Accuracy:</span>
              <span
                className={`ml-1 text-sm text-${color}`}
                data-testid="accuracy-value"
              >
                {data.manualLocation
                  ? "Manual entry"
                  : data.accuracy.toFixed(0) + " m"}
              </span>
            </div>
            <div className="mt-2 flex">
              <EyeDropperIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span
                className="text-sm text-gray-500"
                data-testid="irrigation-value"
              >
                {data.irrigation}
              </span>
            </div>
            <div className="mt-2 flex">
              <ClockIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500" data-testid="time-value">
                {formatTime(
                  data.createddate,
                  data.latitude && data.longitude
                    ? tzlookup(data.latitude, data.longitude)
                    : undefined,
                )}
              </span>
            </div>
          </div>
        </div>

        {/*Approval block*/}
        <div className="mt-4 flex flex-col items-center justify-end">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button
                disabled={!user.user.is_superuser}
                className="flex px-4 py-2 items-center rounded-full text-gray-400 hover:text-gray-600 outline-none border border-1 shadow border-gray-300"
              >
                {/*Statuses */}
                <div className="flex flex-row space-x-4 justify-center">
                  <div className="flex">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm text-green-500">
                      {data.approval_set.reduce(
                        (a, b) => a + (b.approve === true),
                        0,
                      )}
                    </span>
                  </div>
                  <div className="flex">
                    <XCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm text-red-500">
                      {data.approval_set.reduce(
                        (a, b) => a + (b.approve === false),
                        0,
                      )}
                    </span>
                  </div>
                </div>
              </Menu.Button>
            </div>
            {user.user.is_superuser && data.approval_set.length > 0 && (
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {data?.approval_set?.map((c, index) => (
                    <div
                      key={index}
                      className="py-2 text-gray-700 block px-4 py-2 text-sm"
                    >
                      <div className="flex">
                        {c.approve ? (
                          <CheckCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-500"
                            aria-hidden="true"
                          />
                        )}
                        {c.approver}
                      </div>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            )}
          </Menu>
        </div>
      </header>

      {data.crops?.map((c, i) => (
        <CropType crop={c} key={i} onClick={onClick} t={t} index={i} />
      ))}
    </div>
  );
}

function CropType({ crop, onClick, t, index }) {
  const images = [];
  crop.images?.forEach((img, i) => {
    images.push({
      imageUrl: getImageUrl(img),
      label: null,
      testId: `crop-${i}`,
      geolocation: img.geolocation,
      timestamp: img.timestamp,
    });
  });

  crop.boundary_images?.forEach((img, i) => {
    images.push({
      imageUrl: getImageUrl(img),
      label: "BOUNDARY",
      testId: `boundary-${i}`,
      geolocation: img.geolocation,
      timestamp: img.timestamp,
    });
  });
  crop.disease_images?.forEach((img, i) => {
    images.push({
      imageUrl: getImageUrl(img),
      label: "DISEASE",
      testId: `disease-${i}`,
      geolocation: img.geolocation,
      timestamp: img.timestamp,
    });
  });

  const yield_images = [
    ["size_images", "YIELD - PEGGED"],
    ["diagonal_images", "YIELD - DIAGONAL"],
    ["harvested_images", "YIELD - HARVESTED"],
    ["yield_images", "YIELD - MEASUREMENT"],
    ["moisture_images", "YIELD - MOISTURE"],
    ["yield_w1_images", "YIELD - W1 - BAG"],
    ["yield_w2_images", "YIELD - W2 - BAG + DEHUSKED"],
    ["yield_w3_images", "YIELD - W3 - BAG + GRAIN"],
    ["yield_w4_images", "YIELD - W4 - SAMPLE"],
    ["yield_w4_drying_images", "YIELD - DRYING"],
    ["yield_w5_images", "YIELD - W5 - AFTER DRYING"],
  ];
  yield_images.forEach(([field, title]) => {
    crop.yield[field]?.forEach((img, i) => {
      images.push({
        imageUrl: getImageUrl(img),
        label: title,
        testId: `${title.toLowerCase()}-${i}`,
        geolocation: img.geolocation,
        timestamp: img.timestamp,
      });
    });
  });

  const phenologyOptionsList = getPhenologyOptions({ t });

  return (
    <React.Fragment>
      <div className="my-4 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 rounded-lg border">
        <div className="overflow-hidden rounded-l-lg bg-white col-span-1 border-r border-gray-300">
          <div className="bg-gray-50 px-5 py-3 border-b border-gray-300">
            <div className="text-sm">
              <div className="w-0 flex-1">
                <dl>
                  <dd>
                    <div
                      className="text-lg font-medium text-gray-900"
                      data-testid={`${index}-crop`}
                    >
                      {crop.crop}
                      {crop.crop === "other" && ": " + crop.otherCropType}
                      {crop.crop === "preparedland" &&
                        ": " + crop.preparedCropType}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="py-2">
            <div className="flex items-center">
              <div className="ml-5 w-0 flex-1">
                <dl className="gap-y-8">
                  {crop.phenology && crop.crop in phenologyOptionsList && (
                    <dd className="my-4 flex items-center text-sm text-gray-900">
                      <CubeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span data-testid={`${index}-phenology`}>
                        {"Stage " +
                          phenologyOptionsList[crop.crop][crop.phenology]}
                      </span>
                    </dd>
                  )}
                  {crop.crop !== "bareland" && (
                    <dd className="my-4 flex items-center text-sm text-gray-900">
                      <CubeIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />

                      <span data-testid={`${index}-cropDisease`}>
                        {crop.disease === "yes"
                          ? "Has disease or deficiency"
                          : "No disease"}
                        {crop.comment && ": " + crop.comment}
                      </span>
                    </dd>
                  )}
                  {crop.yield.recording === "yes" && (
                    <dd className="my-4 flex text-sm text-gray-900 items-start">
                      <ScaleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div>
                        <span>Yield</span>
                        <ul className="list-disc list-inside text-xs">
                          <li data-testid={`${index}-cropSize`}>
                            Size: {crop.yield.size}
                          </li>
                          <li data-testid={`${index}-cropDiagonal`}>
                            Diagonal: {crop.yield.diagonal}
                          </li>
                          {isGrain(crop.crop) ? (
                            <>
                              {isCorn(crop.crop) && (
                                <>
                                  <li data-testid={`${index}-nPlants`}>
                                    Number of plants: {crop.yield.n_plants}
                                  </li>
                                  <li data-testid={`${index}-nCobs`}>
                                    Number of cobs: {crop.yield.n_cobs}
                                  </li>
                                </>
                              )}
                              {crop.yield.using_moisture === "yes" && (
                                <>
                                  <li data-testid={`${index}-yield`}>
                                    Yield: {crop.yield.yield}
                                  </li>
                                  <li data-testid={`${index}-moisture`}>
                                    Moisture: {crop.yield.moisture}
                                  </li>
                                </>
                              )}
                              {crop.yield.using_moisture === "no" && (
                                <>
                                  <li data-testid={`${index}-yield_w1`}>
                                    W1: {crop.yield.yield_w1}
                                  </li>
                                  {isCorn(crop.crop) && (
                                    <li data-testid={`${index}-yield_w2`}>
                                      W2: {crop.yield.yield_w2}
                                    </li>
                                  )}
                                  <li data-testid={`${index}-yield_w3`}>
                                    W3: {crop.yield.yield_w3}
                                  </li>
                                  <li data-testid={`${index}-yield_w4`}>
                                    W4: {crop.yield.yield_w4}
                                  </li>
                                  <li data-testid={`${index}-yield_w5`}>
                                    W5: {crop.yield.yield_w5}
                                  </li>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <li>Yield: {crop.yield.yield}</li>
                            </>
                          )}
                        </ul>
                      </div>
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto col-span-2 md:col-span-3">
          <div className="relative overflow-hidden rounded-r-lg">
            <div className="gap-1 relative flex overflow-auto z-0">
              {images.map((resource, imageIndex) => {
                return (
                  <div
                    key={imageIndex}
                    className="relative w-64 h-64 snap-start"
                    data-testid={`image-${index}-${resource.testId}`}
                  >
                    {resource.label && (
                      <div className="m-2 absolute inline-flex items-center rounded-md border border-transparent bg-gray-900 opacity-80 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm">
                        {resource.label}
                      </div>
                    )}
                    <button
                      className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 shadow-inner"
                      onClick={() => onClick(resource.imageUrl)}
                      style={{
                        backgroundImage: `url(${resource.imageUrl || ""})`,
                      }}
                    >
                      <img
                        src={resource.imageUrl || ""}
                        alt={resource.title}
                        className="w-full aspect-square hidden"
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(DescriptionList);
