import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// Constants
const STORAGE_WARNING_THRESHOLD = 0.3; // 30%
const STORAGE_CHECK_INTERVAL = 60000; // 1 minute

// Storage utility functions
export const StorageUtils = {
  async getAvailableSpace() {
    const { usage, quota } = await navigator.storage?.estimate();
    const availableBytes = quota - usage;
    const percentAvailable = availableBytes / quota;
    return { availableBytes, percentAvailable };
  },

  formatBytes(bytes) {
    const units = ["B", "KB", "MB", "GB"];
    let value = bytes;
    let unitIndex = 0;

    while (value >= 1024 && unitIndex < units.length - 1) {
      value /= 1024;
      unitIndex++;
    }

    return `${value.toFixed(2)} ${units[unitIndex]}`;
  },
};

// React component
function StorageWarning({ t }) {
  const [showWarning, setShowWarning] = useState(false);
  const [spaceInfo, setSpaceInfo] = useState({ formatted: "", percentage: 0 });

  useEffect(() => {
    const checkAndUpdateStorage = async () => {
      const { availableBytes, percentAvailable } =
        await StorageUtils.getAvailableSpace();
      const formattedSpace = StorageUtils.formatBytes(availableBytes);
      const percentageAvailable = Math.round(percentAvailable * 100);

      setSpaceInfo({
        formatted: formattedSpace,
        percentage: percentageAvailable,
      });
      setShowWarning(percentAvailable < STORAGE_WARNING_THRESHOLD);
    };

    checkAndUpdateStorage();
    const interval = setInterval(checkAndUpdateStorage, STORAGE_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  if (!showWarning) return null;

  return <StorageWarningAlert spaceInfo={spaceInfo} t={t} />;
}

function StorageWarningAlert({ t, spaceInfo }) {
  return (
    <div
      className="mb-2 bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative"
      role="alert"
      data-testid="StorageWarning"
    >
      <strong className="font-bold">
        {t("common.warningTitle", "Warning!")}
      </strong>
      <span className="block sm:inline">
        {" "}
        {t(
          "common.storageWarning",
          `Only ${spaceInfo.formatted} of storage space remaining (${spaceInfo.percentage}% available).
          Before starting a new form please submit the data and clear storage`,
        )}
      </span>
    </div>
  );
}

export default withTranslation()(StorageWarning);
